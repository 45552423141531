import React from "react";

import { useLogout } from "../hooks/useLogout";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// function withRouter(Component) {
//   function ComponentWithRouterProp(props) {
//     let location = useLocation();
//     let navigate = useNavigate();
//     let params = useParams();
//     return <Component {...props} router={{ location, navigate, params }} />;
//   }

//   return ComponentWithRouterProp;
// }

const parseJwt = (token) => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  const { logout } = useLogout();
  let location = useLocation();
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      const decodedJwt = parseJwt(user.token);
      //   if (!decodedJwt) {
      //     logout();
      //     return;
      //   }
      // console.log("decodedJwt", decodedJwt);

      if (decodedJwt && decodedJwt.exp * 1000 < Date.now()) {
        // console.log("#decodedJwt", decodedJwt);
        logout();
      }
    }
  }, [location]);
  //   props.history.listen(() => {
  //     const user = JSON.parse(localStorage.getItem("user"));

  //     if (user) {
  //       const decodedJwt = parseJwt(user.accessToken);

  //       if (decodedJwt.exp * 1000 < Date.now()) {
  //         logout();
  //       }
  //     }
  //   });

  return <div></div>;
};

export default AuthVerify;
