import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";

import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import serverUrl from "../GetServerUrl";
import axios from "axios";

import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Container from "@mui/material/Container";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import VerifiedSharpIcon from "@mui/icons-material/VerifiedSharp";

import Button from "@mui/material/Button";

import { OurContext } from "../context/OurContext";
import { useContext } from "react";

export default function ComprarPlanPage(props) {
  var cardStyle = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    // display: "block",
    // width: "20vw",
    transitionDuration: "0.3s",
    height: { md: "500px" },
  };
  const { getMisAnuncios, miPlan, setMiPlan } = useContext(OurContext);

  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [compradoConExito, setcompradoConExito] = useState(false);
  const [error, setError] = useState(false);
  function getColor(canitdadDeAnuncios) {
    return canitdadDeAnuncios === miPlan ? "#fdd835" : "";
  }

  // function noRepetirPlan() {
  //   return miPlan === elegirPlan ? disabled : "";
  // }
  function elegirPlan(canitdadDeAnuncios) {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (!user) {
      // guardar plan elegido
      setMiPlan(canitdadDeAnuncios);
      navigate("/signup");
      return;
    }

    comprarPlan(canitdadDeAnuncios);
  }
  function comprarPlan(canitdadDeAnuncios) {
    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    let body = {
      canitdadDeAnuncios: canitdadDeAnuncios,
    };
    axios
      .post(`${serverUrl}/comprarPlan`, body, config)
      .then((response) => {
        // console.log("ha comprado", response);
        setcompradoConExito(true);
        setIsLoading(false);
        getMisAnuncios();
      })
      .catch((e) => {
        setError(e.response.data.error);

        setIsLoading(false);
      });
  }
  return (
    <Container fixed>
      <Grid direction={"row"} container item xs={12} spacing={2} mt={4}>
        <Grid item xs={12} md={3}>
          <Card
            // style={cardStyle}
            sx={{
              // minWidth: 275,

              backgroundColor: getColor(5),
              ...cardStyle,
            }}
          >
            <CardContent xs={6}>
              <Box>
                <Typography
                  color={"secondary"}
                  fontWeight={"bold"}
                  variant="h5"
                >
                  Gratis
                </Typography>

                <List>
                  {[
                    "5 Anuncios.",
                    "Visibilidad indefinida.",
                    "Compradores pueden contactarte.",
                    "Oferta Limitada",
                  ].map((element, key) => {
                    return (
                      <ListItem key={key}>
                        <VerifiedSharpIcon
                          fontSize="small"
                          color="secondary"
                          style={{ margin: 6 }}
                        />
                        <ListItemText primary={element} />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </CardContent>
            <CardActions>
              <Grid item xs={12} mb={2}>
                <LoadingButton
                  disabled={compradoConExito}
                  loading={isLoading}
                  // sx={{ position: "center", bottom: -50 }}
                  variant="contained"
                  onClick={() => {
                    elegirPlan(5);
                  }}
                >
                  Elegir
                </LoadingButton>
              </Grid>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            // style={cardStyle}
            sx={{
              //  minWidth: 275,
              //  minHeight: 300,
              backgroundColor: getColor(1),
              ...cardStyle,
            }}
          >
            <CardContent xs={6}>
              <Box>
                <Typography
                  color={"secondary"}
                  fontWeight={"bold"}
                  variant="h5"
                >
                  Plan Individual
                </Typography>
                <List>
                  {[
                    "US$5.00",
                    "1 Anuncio.",
                    // " 30 dias Visibilidad",
                    "Visibilidad indefinida.",

                    "Compradores pueden contactarte.",
                  ].map((element, key) => {
                    return (
                      <ListItem key={key}>
                        <VerifiedSharpIcon
                          fontSize="small"
                          color="secondary"
                          style={{ margin: 6 }}
                        />
                        <ListItemText primary={element} />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </CardContent>
            <CardActions>
              <Grid item xs={12} mb={2}>
                <LoadingButton
                  disabled
                  loading={isLoading}
                  // sx={{ position: "center", bottom: -80 }}
                  variant="contained"
                  onClick={() => {
                    elegirPlan(1);
                  }}
                >
                  Proximamente
                </LoadingButton>
              </Grid>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            // style={cardStyle}
            sx={{
              //  minWidth: 275,
              backgroundColor: getColor(10),
              ...cardStyle,
            }}
          >
            <CardContent xs={6}>
              <Box>
                <Typography
                  color={"secondary"}
                  fontWeight={"bold"}
                  variant="h5"
                >
                  Plan Basico
                </Typography>
                <List>
                  {[
                    "US$10.00",
                    "3 Anuncios.",
                    // " 30 dias Visibilidad",
                    "Visibilidad indefinida.",

                    "Compradores pueden contactarte.",
                  ].map((element, key) => {
                    return (
                      <ListItem key={key}>
                        <VerifiedSharpIcon
                          fontSize="small"
                          color="secondary"
                          style={{ margin: 6 }}
                        />
                        <ListItemText primary={element} />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </CardContent>
            <CardActions>
              <Grid item xs={12} mb={2}>
                <LoadingButton
                  disabled
                  loading={isLoading}
                  // sx={{ position: "center", bottom: -80 }}
                  variant="contained"
                  onClick={() => {
                    elegirPlan(10);
                  }}
                >
                  Proximamente
                </LoadingButton>
              </Grid>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            // style={cardStyle}
            sx={{
              // minWidth: 275,
              backgroundColor: getColor(20),
              ...cardStyle,
            }}
          >
            <CardContent xs={6}>
              <Box>
                <Typography
                  color={"secondary"}
                  fontWeight={"bold"}
                  variant="h5"
                >
                  Plan Super{" "}
                </Typography>
                <List>
                  {[
                    "US$30.00",
                    "10 Anuncios.",
                    // " 30 dias Visibilidad",
                    "Visibilidad indefinida.",

                    "Compradores pueden contactarte.",
                  ].map((element, key) => {
                    return (
                      <ListItem key={key}>
                        <VerifiedSharpIcon
                          fontSize="small"
                          color="secondary"
                          style={{ margin: 6 }}
                        />
                        <ListItemText primary={element} />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </CardContent>

            <CardActions>
              <Grid item xs={12} mb={2}>
                <LoadingButton
                  disabled
                  loading={isLoading}
                  // sx={{ position: "center", bottom: -80 }}
                  variant="contained"
                  onClick={() => {
                    elegirPlan(20);
                  }}
                >
                  Proximamente
                </LoadingButton>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {compradoConExito && (
        <Grid
          direction={"row"}
          container
          item
          xs={12}
          spacing={4}
          justifyContent={"center"}
        >
          <Grid item xs={3} mt={2}>
            <Typography variant="h6">Has comprado con exito!! </Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                // handleSubmit();
                navigate("/agregarAnuncio");
              }}
            >
              Agregar Anuncio
            </Button>
          </Grid>
        </Grid>
      )}
      {error && (
        <Grid
          direction={"row"}
          container
          item
          xs={12}
          spacing={4}
          justifyContent={"center"}
        >
          <Grid item xs={3} mt={2}>
            <Typography variant="h6">{error} </Typography>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
