import { Button, Group, Stack, Text, TextInput } from "@mantine/core";
import Map, { Marker } from "react-map-gl";
import GeocoderControl from "./GeocoderControl";
import axios from "axios";
import { useState } from "react";
import { BsCheckSquareFill } from "react-icons/bs";

const TOKEN =
  "pk.eyJ1IjoiaXZhbmxlYm92a2EiLCJhIjoiY2xpNXhka24zMWtndTNsbHBsZ3RibG9nbyJ9.RdfUV9MYNaHyuGI_IfOd3A";

export default function MyMapBox(props) {
  const [manualSearch, setManualSearch] = useState(false);
  const [direccionConfirmada, setDireccionConfirmada] = useState(false);
  const [viewState, setViewState] = useState({
    longitude: -70.106,
    latitude: 18.6,
    zoom: 6,
  });

  const getReverseGeoCoding = () => {
    let url = "https://api.mapbox.com/geocoding/v5";
    let endpoint = "mapbox.places";
    // https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json"
    // ("https://api.mapbox.com/geocoding/v5/mapbox.places/-73.989,40.733.json?access_token=pk.eyJ1IjoiaXZhbmxlYm92a2EiLCJhIjoiY2xpNXg2bXB2MG1zMjNla2I2aW85YTh1ZSJ9.-V_VUQIXhi7ag7JXglBXig");

    const query = `${url}/${endpoint}/${viewState.longitude},${viewState.latitude}.json?access_token=${TOKEN}`;

    // console.log("query", query);

    axios.get(query).then((response) => {
      // console.log("hola", response.data);

      if (!response.data.features || response.data.features.length == 0) {
        return;
      }
      // the first feature contains most details
      let result = response.data.features[0];
      const location =
        result &&
        (result.center ||
          (result.geometry?.type === "Point" && result.geometry.coordinates));

      let context = result.context;

      props.setAddress(result.place_name);

      const place = context.find((item) => item.id.includes("place"));
      const region = context.find((item) => item.id.includes("region"));
      const locality = context.find((item) => item.id.includes("locality"));

      if (place) {
        props.setPlace(place.text);
      }

      if (region) {
        props.setRegion(region.text);
      }

      if (locality) {
        props.setLocality(locality.text);
      }

      props.setLongitude(location[0]);
      props.setLatitude(location[1]);
    });
  };
  return (
    <Stack

    // bg="green"
    >
      {/* for desktop */}
      <Group
        visibleFrom="sm"
        // bg="blue"
        justify="space-between"
      >
        <Group>
          <Button
            color="#5DA6A6"
            variant={manualSearch ? "outline" : "filled"}
            onClick={() => {
              setManualSearch(false);
            }}
          >
            Escribe la dirección
          </Button>

          <Button
            color="#5DA6A6"
            variant={manualSearch ? "filled" : "outline"}
            onClick={() => {
              setManualSearch(true);
            }}
          >
            {" "}
            Elige manual
          </Button>
        </Group>
        <Button
          color={direccionConfirmada ? "#008080" : "#ff8a65"}
          variant="filled"
          onClick={() => {
            setDireccionConfirmada(true);
            getReverseGeoCoding();
          }}
        >
          {direccionConfirmada ? <BsCheckSquareFill /> : "Confirmar"}
        </Button>
      </Group>

      {/* for mobile */}
      <Group hiddenFrom="sm" justify="space-between">
        <Button
          color="#5DA6A6"
          variant={manualSearch ? "outline" : "filled"}
          onClick={() => {
            setManualSearch(false);
          }}
        >
          Escribe la dirección
        </Button>

        <Button
          color="#5DA6A6"
          variant={manualSearch ? "filled" : "outline"}
          onClick={() => {
            setManualSearch(true);
          }}
        >
          {" "}
          Elige manual
        </Button>
      </Group>
      
      <Group grow>
        <Map
          {...viewState}
          onMove={(evt) => {
            // console.log(evt.viewState);
            setDireccionConfirmada(false);
            setViewState(evt.viewState);
          }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxAccessToken={TOKEN}
          style={{ height: "500px" }}
        >
          {!manualSearch && (
            <GeocoderControl
              mapboxAccessToken={TOKEN}
              position="top-left"
              countries="do"
              limit={8}
              setAddress={props.setAddress}
              setPlace={props.setPlace}
              setRegion={props.setRegion}
              setLocality={props.setLocality}
              setLatitude={props.setLatitude}
              setLongitude={props.setLongitude}
            />
          )}
          {manualSearch && (
            <Marker
              longitude={viewState.longitude}
              latitude={viewState.latitude}
              anchor="bottom"
            />
          )}
        </Map>
      </Group>
      <Group grow>
        <TextInput
          disabled
          // label="Dirección"
          value={props.address}
          placeholder="Busca tu dirección en el mapa"
          error={props.address ? null : "Dirección es requerido"}
        />
      </Group>
      <Button
        hiddenFrom="sm"
        color={direccionConfirmada ? "#008080" : "#ff8a65"}
        variant="filled"
        onClick={() => {
          setDireccionConfirmada(true);
          getReverseGeoCoding();
        }}
      >
        {direccionConfirmada ? <BsCheckSquareFill /> : "Confirmar Dirección"}
      </Button>
      <Text>{props.errors.address}</Text>
    </Stack>
  );
}
