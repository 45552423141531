import { Center, Fieldset, Group } from "@mantine/core";
import { TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { PasswordInput, Stack, Button } from "@mantine/core";
import { useState } from "react";
import { IconX, IconCheck } from "@tabler/icons-react";
import { Progress, Text, Popover, Box, rem, Paper } from "@mantine/core";
import { useEffect } from "react";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useSignup } from "../hooks/useSignup";
import serverUrl from "../GetServerUrl";
import axios from "axios";

export function MyforgotPassword() {
  const { signup, error, isLoading } = useSignup();
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (values) => {
    axios
      .post(`${serverUrl}/forgot-password`, { email: values.email }, {})
      .then((response) => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const [value, setValue] = useState("");

  const [visible, { toggle }] = useDisclosure(false);
  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  // const handleSubmit = async (values) => {
  //   // console.log("###", checks)

  //   const result = await signup(values.email);

  //   console.log("result", result);
  //   if (result) {
  //     setSignUpSuccessfull(true);
  //   } else {
  //     console.log("result is false", error);
  //   }
  // };

  return (
    <Center>
      <Paper p={40} radius={10} withBorder>
        <Stack>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              mt={10}
              label="Email"
              placeholder="Email"
              {...form.getInputProps("email")}
            />

            <Group justify="flex-end" mt={"md"}>
              {submitted ? (
                <Group>
                  <Button
                    color="#ff8a65"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    {" "}
                    Back to Login
                  </Button>

                  <Text fw={700}>
                    The reset link has been sent to your email address
                  </Text>
                </Group>
              ) : (
                <Button type="submit" color="#ff8a65">
                  <Text fw={700} c={"white"}>
                    Enviar
                  </Text>
                </Button>
              )}
            </Group>
          </form>
        </Stack>
      </Paper>
    </Center>
  );
}
