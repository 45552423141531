import { Center, Fieldset, Group } from "@mantine/core";
import { TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { PasswordInput, Stack, Button } from "@mantine/core";
import { useState } from "react";
import { IconX, IconCheck } from "@tabler/icons-react";
import { Progress, Text, Popover, Box, rem, Paper } from "@mantine/core";
import { useEffect } from "react";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useSignup } from "../hooks/useSignup";

function PasswordRequirement({ meets, label }) {
  return (
    <Text
      c={meets ? "teal" : "red"}
      style={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? (
        <IconCheck style={{ width: rem(14), height: rem(14) }} />
      ) : (
        <IconX style={{ width: rem(14), height: rem(14) }} />
      )}{" "}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

const requirements = [
  { re: /[0-9]/, label: "Incluye Numero" },
  { re: /[a-z]/, label: "Incluye letra minuscula" },
  { re: /[A-Z]/, label: "Incluye letra mayuscula" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Incluye  simbolo" },
];

function getStrength(password) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

export function Mysingup() {
  const [signUpSuccessfull, setSignUpSuccessfull] = useState(false);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const { signup, error, isLoading } = useSignup();
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(value)}
    />
  ));

  const strength = getStrength(value);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  const [visible, { toggle }] = useDisclosure(false);
  const form = useForm({
    initialValues: {
      name: "",
      apellido: "",
      email: "",
      password: "",
      repeatPassword: "",
    },
    validate: {
      name: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      apellido: (value) =>
        value.length < 2 ? "Apellido must have at least 2 letters" : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      age: (value) =>
        value < 18 ? "You must be at least 18 to register" : null,

      password: (value) =>
        value.length < 2 ? "password must have at least 2 letters" : null,

      password: (value) => {
        for (const requirement of requirements) {
          if (!requirement.re.test(value)) {
            return "No " + requirement.label;
          }
        }

        return null;
      },

      repeatPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });

  const handleSubmit = async (values) => {
    // console.log("###", values);
    const result = await signup(
      values.email,
      values.password,
      values.name,
      values.apellido
    );

    console.log("result", result);
    if (result) {
      setSignUpSuccessfull(true);
    } else {
      console.log("result is false", error);
    }
  };

  return (
    <Center>
      <Paper p={40} radius={10} withBorder>
        {!signUpSuccessfull && (
          <Stack>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <TextInput
                mt={10}
                label="Nombre"
                placeholder="Nombre"
                {...form.getInputProps("name")}
              />
              <TextInput
                mt={10}
                label="Apellido"
                placeholder="Apellido"
                {...form.getInputProps("apellido")}
              />
              <TextInput
                mt={10}
                label="Email"
                placeholder="Email"
                {...form.getInputProps("email")}
              />

              <Popover
                opened={popoverOpened}
                position="bottom"
                width="target"
                transitionProps={{ transition: "pop" }}
              >
                <Popover.Target>
                  <div
                    onFocusCapture={() => setPopoverOpened(true)}
                    onBlurCapture={() => setPopoverOpened(false)}
                  >
                    <PasswordInput
                      mt={10}
                      withAsterisk
                      label="Contraseña"
                      placeholder="Contraseña"
                      visible={visible}
                      onVisibilityChange={toggle}
                      {...form.getInputProps("password")}
                      onChange={(event) => {
                        // we have to do this because mantine form has it's own onChange
                        // and the password strength meter has on change too
                        // so we are trying to combine them like this
                        let originalOnChange =
                          form.getInputProps("password").onChange;
                        originalOnChange(event);
                        // console.log("%%%%%", form.getInputProps("password"));
                        setValue(event.currentTarget.value);
                      }}
                    />
                  </div>
                </Popover.Target>
                <Popover.Dropdown>
                  <Progress color={color} value={strength} size={5} mb="xs" />
                  <PasswordRequirement
                    label="Incluye por lo menos 8 caracteres"
                    meets={value.length > 7}
                  />
                  {checks}
                </Popover.Dropdown>
              </Popover>
              <PasswordInput
                mt={10}
                {...form.getInputProps("repeatPassword")}
                withAsterisk
                label="Confirmar Contraseña"
                visible={visible}
                onVisibilityChange={toggle}
              />
              <Group justify="flex-end" mt={"md"}>
                <Button type="submit" color="#ff8a65">
                  <Text fw={700} c={"white"}>
                    Enviar
                  </Text>
                </Button>
              </Group>
            </form>

            <Group mt={20}>
              <Text fw={700}>Ya tengo una cuenta</Text>
              <Button
                type="submit"
                color="white"
                onClick={() => {
                  navigate("/login");
                }}
              >
                <Text fw={700} c={"#ff8a65"}>
                  Inicia Sesión
                </Text>
              </Button>
            </Group>
          </Stack>
        )}
        {!signUpSuccessfull && <Text fw={700}>{error}</Text>}

        {signUpSuccessfull && (
          <Stack>
            <Text fw={700} c={"green"}>
              Registro Exitoso
            </Text>
            <Text fw={700} c={"green"}>
              Por favor, revisar su correo y dar click en el link enviado!
            </Text>
            <Button
              type="submit"
              color="white"
              onClick={() => {
                navigate("/login");
              }}
            >
              <Text fw={700} c={"#ff8a65"}>
                Inicia Sección
              </Text>
            </Button>
          </Stack>
        )}
      </Paper>
    </Center>
  );
}
