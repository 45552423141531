import * as React from "react";

import { useState } from "react";

import imag1 from "./photos/imag.jpg";
import { Grid, NumberInput, Paper } from "@mantine/core";
import { OurContext } from "../context/OurContext";
import { useContext } from "react";
import { IoMdOptions } from "react-icons/io";

import { Accordion } from "@mantine/core";

import {
  BackgroundImage,
  Button,
  Group,
  Box,
  Select,
  Stack,
} from "@mantine/core";

import { useForm } from "@mantine/form";

function SearchContainer(props) {
  const form = useForm({
    initialValues: {
      moneda: "RD",
    },
  });

  const { placeOptions, regionOptions, localityOptions, setFilters, filters } =
    useContext(OurContext);

  const [accordionOpened, setAccordionOpened] = useState(null);

  React.useEffect(() => {
    // console.log("filters1", filters, Object.keys(filters).length === 0);
    if (Object.keys(filters).length === 0) {
      // console.log("filters###", filters);

      form.setValues({
        place: null,
        locality: null,
        region: null,
        proposito: null,
        propiedad: null,
        habitaciones: null,
        metrosMin: null,
        metrosMax: null,
        precioMin: undefined,
        precioMax: undefined,
        moneda: null,
      });
    }
  }, [filters]);

  const handleSubmit = (values) => {
    for (const key of Object.keys(values)) {
      let value = values[key];
      if (value === null || value === "") {
        delete values[key];
      }
    }

    setFilters(values);
  };

  return (
    <Group
      grow
      // bg="red"
    >
      <Box
        mx="auto"
        // pb={50}
        // bg="blue"
        //  maw={1300}
      >
        <BackgroundImage visibleFrom="sm" src={imag1} radius="sm">
          <Group
            pt={150}
            pb={150}
            pl={80}
            pr={80}
            grow
            //  mr={80}
            //   ml={80}
          >
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <Paper
                shadow="xs"
                radius="md"
                p="xl"
                // bg={"salmon"}
              >
                <Grid mt={8}>
                  <Grid.Col span={10}>
                    {" "}
                    <Accordion
                      unstyled
                      // variant="filled"
                      // bg={"yellow"}
                      value={"item-1"}
                    >
                      <Accordion.Item value="item-1">
                        <Accordion.Panel>
                          {" "}
                          <Group justify="center" grow>
                            <Group grow>
                              <Select
                                clearable
                                searchable
                                placeholder="Lugar"
                                data={placeOptions}
                                {...form.getInputProps("place")}
                              />
                            </Group>
                            <Group grow>
                              <Select
                                clearable
                                placeholder="Localidad"
                                data={localityOptions}
                                {...form.getInputProps("locality")}
                              />
                            </Group>

                            <Group grow>
                              <Select
                                clearable
                                placeholder="Región"
                                data={regionOptions}
                                {...form.getInputProps("region")}
                              />
                            </Group>
                            <Group grow>
                              <Select
                                clearable
                                placeholder="Proposito"
                                data={propositos}
                                {...form.getInputProps("proposito")}
                              />
                            </Group>
                            <Group grow>
                              <Select
                                clearable
                                placeholder="Propiedad"
                                data={typeOfHause}
                                {...form.getInputProps("propiedad")}
                              />
                            </Group>
                          </Group>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Group
                      // bg="cyan"
                      justify="space-between"
                    >
                      <Button
                        color="#5DA6A6"
                        // variant="transparent"
                        onClick={() => {
                          if (accordionOpened == null) {
                            setAccordionOpened("Apples");
                          } else {
                            setAccordionOpened(null);
                          }
                        }}
                      >
                        <Group>
                          <IoMdOptions size={"2em"} />
                          {/* Filtros */}
                        </Group>
                      </Button>
                      <Button
                        color="#ff8a65"
                        type="submit"
                        // onClick={() => {
                        //   console.log("temporalFilter", temporalFilter);
                        //   setFilters(temporalFilter);
                        // }}
                      >
                        Buscar
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>

                <Grid mt={10}>
                  <Grid.Col span={10}>
                    <Accordion
                      // bg="blue"
                      unstyled
                      // bg={"green"}
                      value={accordionOpened}
                    >
                      <Accordion.Item key={"Apples"} value={"Apples"}>
                        <Accordion.Panel>
                          <Group grow>
                            <Group grow>
                              <NumberInput
                                placeholder="Habitaciones"
                                {...form.getInputProps("habitaciones")}
                                allowNegative={false}
                              />
                            </Group>
                            <Group grow>
                              <NumberInput
                                placeholder="MetrosMin"
                                {...form.getInputProps("metrosMin")}
                                allowNegative={false}
                              />
                            </Group>

                            <Group grow>
                              <NumberInput
                                placeholder="MetrosMax"
                                {...form.getInputProps("metrosMax")}
                                allowNegative={false}
                              />
                            </Group>

                            <Group grow>
                              <NumberInput
                                placeholder="PrecioMin"
                                {...form.getInputProps("precioMin")}
                                thousandSeparator=","
                                allowNegative={false}
                              />
                            </Group>

                            <Group grow>
                              <NumberInput
                                placeholder="PrecioMax"
                                {...form.getInputProps("precioMax")}
                                thousandSeparator=","
                                allowNegative={false}
                              />
                            </Group>
                          </Group>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Accordion
                      // bg="blue"
                      unstyled
                      // bg={"green"}
                      value={accordionOpened}
                    >
                      <Accordion.Item key={"Apples"} value={"Apples"}>
                        <Accordion.Panel>
                          <Group grow>
                            <Select
                              placeholder="Moneda"
                              data={["RD", "US"]}
                              // onChange={(event, value) => {
                              //   actulizarFilters(value, "moneda");
                              // }}
                              {...form.getInputProps("moneda")}
                            />
                          </Group>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  </Grid.Col>
                </Grid>
              </Paper>
            </form>
          </Group>
        </BackgroundImage>

        <BackgroundImage hiddenFrom="sm" src={imag1} radius="sm">
          <Group
            p={20}
            // pt={230}
            // pb={230}
            // pl={80}
            // pr={80}
            grow
            //  mr={80}
            //   ml={80}
          >
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <Paper
                shadow="xs"
                radius="md"
                p="xl"
                // bg={"salmon"}
              >
                <Grid mt={8}>
                  <Grid.Col>
                    {" "}
                    <Accordion
                      unstyled
                      // variant="filled"
                      // bg={"yellow"}
                      value={"item-1"}
                    >
                      <Accordion.Item value="item-1">
                        <Accordion.Panel>
                          {" "}
                          <Stack justify="center">
                            <Group grow>
                              <Select
                                clearable
                                searchable
                                placeholder="Lugar"
                                data={placeOptions}
                                {...form.getInputProps("place")}
                              />
                            </Group>
                            <Group grow>
                              <Select
                                clearable
                                placeholder="Localidad"
                                data={localityOptions}
                                {...form.getInputProps("locality")}
                              />
                            </Group>

                            <Group grow>
                              <Select
                                clearable
                                placeholder="Región"
                                data={regionOptions}
                                {...form.getInputProps("region")}
                              />
                            </Group>
                            <Group grow>
                              <Select
                                clearable
                                placeholder="Proposito"
                                data={propositos}
                                {...form.getInputProps("proposito")}
                              />
                            </Group>

                            <Group grow>
                              <Select
                                clearable
                                placeholder="Propiedad"
                                data={typeOfHause}
                                {...form.getInputProps("propiedad")}
                              />
                            </Group>
                            <Group grow>
                              <Grid>
                                <Grid.Col>
                                  <Accordion
                                    // bg="blue"
                                    unstyled
                                    // bg={"green"}
                                    value={accordionOpened}
                                  >
                                    <Accordion.Item
                                      key={"Apples"}
                                      value={"Apples"}
                                    >
                                      <Accordion.Panel>
                                        <Group grow>
                                          <Group grow>
                                            <NumberInput
                                              placeholder="Habitaciones"
                                              {...form.getInputProps(
                                                "habitaciones"
                                              )}
                                              allowNegative={false}
                                            />
                                          </Group>
                                          <Group grow>
                                            <NumberInput
                                              placeholder="MetrosMin"
                                              {...form.getInputProps(
                                                "metrosMin"
                                              )}
                                              allowNegative={false}
                                            />
                                          </Group>

                                          <Group grow>
                                            <NumberInput
                                              placeholder="MetrosMax"
                                              {...form.getInputProps(
                                                "metrosMax"
                                              )}
                                              allowNegative={false}
                                            />
                                          </Group>

                                          <Group grow>
                                            <NumberInput
                                              placeholder="PrecioMin"
                                              {...form.getInputProps(
                                                "precioMin"
                                              )}
                                              thousandSeparator=","
                                              allowNegative={false}
                                            />
                                          </Group>

                                          <Group grow>
                                            <NumberInput
                                              placeholder="PrecioMax"
                                              {...form.getInputProps(
                                                "precioMax"
                                              )}
                                              thousandSeparator=","
                                              allowNegative={false}
                                            />
                                          </Group>

                                          <Group grow>
                                            <Select
                                              placeholder="Moneda"
                                              data={["RD", "US"]}
                                              // onChange={(event, value) => {
                                              //   actulizarFilters(value, "moneda");
                                              // }}
                                              {...form.getInputProps("moneda")}
                                            />
                                          </Group>
                                        </Group>
                                      </Accordion.Panel>
                                    </Accordion.Item>
                                  </Accordion>
                                </Grid.Col>
                              </Grid>
                            </Group>

                            <Group grow>
                              <Button
                                color="#5DA6A6"
                                // variant="transparent"
                                onClick={() => {
                                  if (accordionOpened == null) {
                                    setAccordionOpened("Apples");
                                  } else {
                                    setAccordionOpened(null);
                                  }
                                }}
                              >
                                <Group>
                                  <IoMdOptions size={"2em"} />
                                  {/* Filtros */}
                                </Group>
                              </Button>
                            </Group>
                            <Group grow>
                              <Button
                                color="#ff8a65"
                                type="submit"
                                // onClick={() => {
                                //   console.log("temporalFilter", temporalFilter);
                                //   setFilters(temporalFilter);
                                // }}
                              >
                                Buscar
                              </Button>
                            </Group>
                          </Stack>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  </Grid.Col>
                </Grid>
              </Paper>
            </form>
          </Group>
        </BackgroundImage>
      </Box>
    </Group>
  );
}
const propositos = ["venta", "alquiler"];
const typeOfHause = [
  "Casa",
  "Apartamento",
  "Villa",
  "Solar",
  "Terreno",
  "Penthouse",
  "Finca",
  "Lofts",
  "Estudios",
  "Oficina",
  "Hotel",
  "Townhouse",
  "Otro",
  "Naves",
  "Locales",
  "Turistico",
  "Estacion de Combustible",
  "Proyecto",
  "Negocio",
  "Edificio",
];
export default SearchContainer;
