import { Center, Fieldset, Group } from "@mantine/core";
import { TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { PasswordInput, Stack, Button } from "@mantine/core";
import { useState } from "react";
import { IconX, IconCheck } from "@tabler/icons-react";
import { Progress, Text, Popover, Box, rem, Paper } from "@mantine/core";
import { useEffect } from "react";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";

import serverUrl from "../GetServerUrl";
import axios from "axios";

export function MyRequestEmailConfirmation() {
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (values) => {
    axios
      .post(
        `${serverUrl}/get-email-confirmation-link`,
        { email: values.email },
        {}
      )
      .then((response) => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  return (
    <Center>
      <Paper p={80} radius={10} withBorder>
        <Stack>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              mt={10}
              label="Enviaremos un link de confrimación a su email"
              placeholder="Email"
              {...form.getInputProps("email")}
            />

            <Group justify="flex-end" mt={"md"}>
              {submitted ? (
                <Group>
                  <Button
                    color="#ff8a65"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    {" "}
                    Back to Login
                  </Button>

                  <Text fw={700}>
                    The link has been sent to your email address
                  </Text>
                </Group>
              ) : (
                <Button type="submit" color="#ff8a65">
                  <Text fw={700} c={"white"}>
                    Enviar
                  </Text>
                </Button>
              )}
            </Group>
          </form>
        </Stack>
      </Paper>
    </Center>
  );
}
