import React, { Component, createContext } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import serverUrl from "../GetServerUrl";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
export const OurContext = createContext();


function OurContextProvider(props) {
  const navigate = useNavigate();
  const { user } = useAuthContext();


  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [anuncios, setAnuncios] = useState([]);
  const [anuncioCount, setAnuncioCount] = useState(0);
  const [placeOptions, setPlaceOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [localityOptions, setLocalityOptions] = useState([]);
  const [misAnuncios, setMisAnuncios] = useState([]);
  const [miPlan, setMiPlan] = useState(null);
  const [anunciosDisponibles, setAnunciosDisponibles] = useState(5);
  const [anuncioElegido, setAnuncioElegido] = useState(null);

  const [cantidadDePaginas, setCantidadDePaginas] = useState(1);

  function getAllAnuncios() {
    const body = { filters: filters, page: currentPage - 1 };

    axios.post(`${serverUrl}/allAnuncios`, body).then((response) => {
      setAnuncios(response.data.anuncios);
      setAnuncioCount(response.data.count);

      setPlaceOptions(response.data.placeOptions);
      setRegionOptions(response.data.regionOptions);
      setLocalityOptions(response.data.localityOptions);
    });
  }

  function getMisAnuncios() {
    // console.log("getmisAnun", user);

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .post(`${serverUrl}/misAnuncios`, {}, config)
      .then((response) => {
        setMisAnuncios(response.data.listaDeTodos);
        // console.log("response.data", response.data);
        setAnunciosDisponibles(response.data.canitdadDeAnunciosDisponibles);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          if (
            error.response.status === 401 &&
            error.response.statusText === "Unauthorized"
          ) {
            navigate("/");
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    getMisAnuncios();
  }, [user]);

  const value = {
    anuncios: anuncios,
    setAnuncios: setAnuncios,
    filters: filters,
    setFilters: setFilters,
    misAnuncios,
    setMisAnuncios,
    currentPage,
    setCurrentPage,
    miPlan,
    setMiPlan,
    anunciosDisponibles,
    setAnunciosDisponibles,
    anuncioCount,
    setAnuncioCount,
    cantidadDePaginas,
    setCantidadDePaginas,

    getAllAnuncios: getAllAnuncios,
    placeOptions,
    setPlaceOptions,
    regionOptions,
    setRegionOptions,
    localityOptions,
    setLocalityOptions,
    anuncioElegido, setAnuncioElegido,
    showDetailedView,
    getMisAnuncios
  };

  useEffect(() => {
    getAllAnuncios();
  }, [filters, misAnuncios, currentPage]);

  useEffect(() => {
    const postsPerPage = 12;
    let nuevaCantidadDePAginas = Math.ceil(anuncioCount / postsPerPage);
    setCantidadDePaginas(nuevaCantidadDePAginas);
  }, [anuncios]);

 

  function showDetailedView(anuncio) {
    navigate(`/anuncioDetailedView/${anuncio._id}`);
  }

  return (
    <OurContext.Provider value={{ ...value }}>
      {props.children}
    </OurContext.Provider>
  );
}

export default OurContextProvider;
