import { Image, Card, Group, Button, Center } from "@mantine/core";
import { Carousel } from "@mantine/carousel";

import "@mantine/carousel/styles.css";
import serverUrl from "../GetServerUrl";
import { OurContext } from "../context/OurContext";
import { useContext } from "react";

import classes from "./mantine_css/CarouselCard.module.css";

export function MyAdsMap(props) {
  const { showDetailedView } = useContext(OurContext);

  //   let listaDePhotosObjects = [];
  const item = props.anuncio;
  //   const listaDePhotos = [...item.photo];

  //   listaDePhotosObjects = listaDePhotos.map((photoString, index) => {
  //     return `${serverUrl}/image/${photoString}`;
  //   });

  //   const slides = listaDePhotosObjects.map((image) => (
  //     <Carousel.Slide key={image}>
  //       <Image src={image} height={220} />
  //     </Carousel.Slide>
  //   ));

  const slides = item.image_urls.map((image_url, index) => (
    <Carousel.Slide key={index}>
      <Image src={image_url} height={220} />
      {/* <Image src={"https://tucasataina.b-cdn.net/images/001e1f05-ced2-4761-83b4-cc3c67ea3aa0.webp"} height={220} /> */}
    </Carousel.Slide>
  ));

  return (
    // <Center maw={400} bg="var(--mantine-color-gray-light)">
    //   {/* <NavigationProgress /> */}
    <Card
      radius="md"
      //   withBorder
      // padding="xl"
    >
      <Card.Section>
        <Carousel
          withIndicators
          loop
          classNames={{
            root: classes.carousel,
            controls: classes.carouselControls,
            indicator: classes.carouselIndicator,
          }}
        >
          {slides}
        </Carousel>
      </Card.Section>

      <Card.Section>
        <Group mt="md" grow 
        // bg={"blue"}
        >
          <Button
            // onClick={() => nprogress.start(showDetailedView(item))}
            onClick={() => {
              showDetailedView(item);
            }}
            color="#ff8a65"
            radius="md"
          >
            Más Información
          </Button>
        </Group>
      </Card.Section>
    </Card>
    // </Center>
  );
}
