import {
  Group,
  Button,
  Text,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
  useMantineTheme,
  ActionIcon,
  AppShell,
  Flex,
  Stack,
} from "@mantine/core";
// import { MantineLogo } from "@mantinex/mantine-logo";
import { useDisclosure } from "@mantine/hooks";
import {
  IconNotification,
  IconCode,
  IconBook,
  IconChartPie3,
  IconFingerprint,
  IconCoin,
  IconChevronDown,
} from "@tabler/icons-react";
import classes from "./mantine_css/HeaderMegaMenu.module.css";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

import { OurContext } from "../context/OurContext";
import { useContext } from "react";

export function MyHeader() {
  const { setCurrentPage, anunciosDisponibles, setFilters } =
    useContext(OurContext);
  const navigate = useNavigate();

  //ninja code los 3
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const toWhomShowAgregarAnuncio = user && anunciosDisponibles > 0;

  const handleClick = () => {
    logout();
  };

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const theme = useMantineTheme();

  /////////////////
  const [opened, { toggle }] = useDisclosure();
  return (
    <Group grow>
      <AppShell.Header bg={"linear-gradient(to right, #ff8a65, #ffab91)"}>
        <Flex
          // mih={50}
          gap="md"
          justify="space-between"
          align="center"
          direction="row"
          wrap="wrap"
          p={20}
        >
          {/* <Group justify="space-between" bg={"green"}> */}
          <ActionIcon
            size="xl"
            variant="transparent"
            onClick={() => {
              navigate("/");
              setCurrentPage(1);
              setFilters({});
            }}
          >
            <img src={require("../logo_vertical1.png")} />
          </ActionIcon>
          <Group
          // bg={"red"}
          >
            <Box radius={"lg"}>
              <header className={classes.header}>
                <Group justify="space-between" h="100%">
                  <Group h="100%" gap={"xs"} visibleFrom="sm">
                    {user && (
                      <Group>
                        <Text c={"white"} fw={700}>
                          {user.name}
                        </Text>

                        <Button
                          color="white"
                          key="MisAnuncios"
                          onClick={() => {
                            closeDrawer();
                            navigate("/misAnuncios");
                          }}
                        >
                          <Text fw={700} c={"#ff8a65"}>
                            MIS ANUNCIOS
                          </Text>
                        </Button>
                      </Group>
                    )}

                    {toWhomShowAgregarAnuncio && (
                      <Button
                        color="white"
                        onClick={() => {
                          closeDrawer();
                          navigate("/agregarAnuncio");
                        }}
                      >
                        <Text fw={700} c={"#ff8a65"}>
                          AGREGAR ANUNCIO
                        </Text>
                      </Button>
                    )}

                    {!user && (
                      <Button
                        // variant="transparent"
                        color="white"
                        // color="rgba(255, 255, 255, 0.99)"
                        onClick={() => {
                          closeDrawer();

                          !user
                            ? navigate("/signup")
                            : navigate("/agregarAnuncio");
                        }}
                      >
                        <Text fw={700} c={"#ff8a65"}>
                          ANUNCIATE
                        </Text>
                      </Button>
                    )}

                    {user && (
                      <Group>
                        <Button color="white" onClick={handleClick}>
                          <Text c={"#ff8a65"} fw={700}>
                            SALIR
                          </Text>
                        </Button>
                      </Group>
                    )}
                  </Group>

                  {!user && (
                    <Group visibleFrom="sm">
                      <Button
                        // variant="transparent"
                        color="white"
                        onClick={() => {
                          closeDrawer();
                          navigate("/login");
                        }}
                      >
                        <Text fw={700} c={"#ff8a65"}>
                          INICIA SESSIÓN
                        </Text>
                      </Button>
                      <Button
                        // variant="transparent"
                        color="white"
                        onClick={() => {
                          closeDrawer();
                          navigate("/signup");
                        }}
                      >
                        <Text fw={700} c={"#ff8a65"}>
                          REGISTRATE
                        </Text>
                      </Button>
                    </Group>
                  )}

                  <Burger
                    opened={drawerOpened}
                    onClick={toggleDrawer}
                    hiddenFrom="sm"
                  />
                </Group>
              </header>

              <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="100%"
                padding="md"
                title={user ? user.name : "Menu"}
                hiddenFrom="sm"
                zIndex={1000000}
              >
                <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
                  <Divider my="sm" />

                  <Stack align="flex-start">
                    <Button
                      variant="transparent"
                      key="MainPage"
                      onClick={() => {
                        closeDrawer();
                        navigate("/");
                      }}
                    >
                      <Text textAlign="center">Inicio</Text>
                    </Button>

                    <Button
                      variant="transparent"
                      onClick={() => {
                        closeDrawer();

                        !user
                          ? navigate("/signup")
                          : navigate("/agregarAnuncio");
                      }}
                    >
                      <Text textAlign="center">
                        {!user ? "Anunciate" : "Agregar Anuncio"}
                      </Text>
                    </Button>

                    {user && (
                      <Button
                        variant="transparent"
                        key="MisAnuncios"
                        onClick={() => {
                          closeDrawer();
                          navigate("/misAnuncios");
                        }}
                      >
                        <Text textAlign="center">Mis Anuncios</Text>
                      </Button>
                    )}
                  </Stack>
                  <Divider my="sm" />

                  <Group justify="center" grow pb="xl" px="md">
                    {/* movil */}
                    {!user && (
                      <Button
                        variant="default"
                        onClick={() => {
                          closeDrawer();
                          navigate("/login");
                        }}
                      >
                        Inicia Sesión
                      </Button>
                    )}
                    {!user && (
                      <Button
                        onClick={() => {
                          closeDrawer();
                          navigate("/signup");
                        }}
                      >
                        Registrate
                      </Button>
                    )}

                    {user && (
                      <Button onClick={handleClick}>
                        <Text textAlign="center">Salir</Text>
                      </Button>
                    )}
                  </Group>
                </ScrollArea>
              </Drawer>
            </Box>
          </Group>
        </Flex>
      </AppShell.Header>
    </Group>
  );
}
