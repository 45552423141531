import * as React from "react";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { MdAddCircle } from "react-icons/md";
import { Affix, Button, rem, Group, Grid, Stack } from "@mantine/core";
import { TestCardMantine } from "./TestCardMantine";

import { OurContext } from "../context/OurContext";
import { useContext } from "react";
import serverUrl from "../GetServerUrl";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function MyAds(props) {
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    misAnuncios,
    getMisAnuncios,
    anunciosDisponibles,
    currentPage,
    setCurrentPage,
  } = useContext(OurContext);
  const toWhomShowLinkToBuy = anunciosDisponibles <= 0;
  function deleteAnuncioDelServer(anuncioId) {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .delete(`${serverUrl}/${anuncioId}`, config)
      .then((response) => {
        console.log("was successfully deleted");

        getMisAnuncios();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getMisAnuncios();
  }, [user]);

  return (
    <>
      <Group justify="center">
        {anunciosDisponibles <= 0 && (
          <Affix position={{ bottom: 40, right: 100 }}>
            <Button
              variant="transparent"
              leftSection={
                <MdAddCircle
                  style={{ width: rem(40), height: rem(40) }}
                  color="rgba(5, 50, 250, 1)"
                />
              }
              justify="center"
              onClick={() => {
                navigate("/agregarAnuncio");
              }}
            ></Button>
          </Affix>
        )}

        {toWhomShowLinkToBuy && (
          <Group grow justify="center" gap={"xl"}>
            <Button
              onClick={() => {
                navigate("/comprarPlanPage");
              }}
            >
              Elige tu plan
            </Button>
          </Group>
        )}

        {/* below for desktop */}

        <Group
          visibleFrom="sm"
          justify="center"
        >
          {misAnuncios.map((anuncio, index) => {
            return (
              <Group
                w={"400px"}
                key={index}
                // bg={"grape"}
              >
                <TestCardMantine
                  key={index}
                  anuncio={anuncio}
                  queHacerOnDelete2={deleteAnuncioDelServer}
                  isLoading={isLoading}
                />
              </Group>
            );
          })}
        </Group>

        {/* below for mobile */}
        <Stack
          // bg="grape"
          hiddenFrom="sm"
          justify="center"
        >
          {misAnuncios.map((anuncio, index) => {
            return (
              <Group grow key={index}>
                <TestCardMantine
                  key={index}
                  anuncio={anuncio}
                  queHacerOnDelete2={deleteAnuncioDelServer}
                  isLoading={isLoading}
                />
              </Group>
            );
          })}
        </Stack>
      </Group>
      {/* <Group mt={10}>
        <Pagination
          total={misAnuncios}
          color={"#ff8a65"}
          value={currentPage}
          onChange={setCurrentPage}
        />
      </Group> */}
    </>
  );
}
