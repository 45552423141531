import * as React from "react";

import { Affix, Button, Group, Stack, Grid } from "@mantine/core";
import { TestCardMantine } from "./TestCardMantine";

import { OurContext } from "../context/OurContext";
import { useContext } from "react";
import SearchContainer from "./SearchContainer";

import AllAnunciosMap from "./AllAnunciosMap";
import MyPagination from "./MyPagination";

import { CiBoxList } from "react-icons/ci";
import { TbWorld } from "react-icons/tb";

export function MyMainPage(props) {
  const { anuncios } = useContext(OurContext);

  const [showMap, setShowMap] = React.useState(false);

  return (
    // <Center mb={80} bg={"blue"} >
    <Stack
      //  bg="gray"
      mb={30}
    >
      <Affix
        position={{ bottom: 60, right: 10 }}
        style={{ zIndex: 1000 }}
        hiddenFrom="sm"
      >
        <Button
          hiddenFrom="sm"
          color="#ff8a65"
          radius="xl"
          size="sm"
          onClick={() => setShowMap(!showMap)}
        >
          {showMap ? <CiBoxList size={20} /> : <TbWorld size={20} />}
        </Button>
      </Affix>

      <Affix
        position={{ bottom: 50, right: 20 }}
        style={{ zIndex: 1000 }}
        visibleFrom="sm"
      >
        <Button
          visibleFrom="sm"
          color="#ff8a65"
          radius="xl"
          size="md"
          rightSection={
            showMap ? <CiBoxList size={20} /> : <TbWorld size={20} />
          }
          onClick={() => setShowMap(!showMap)}
        >
          {showMap ? "Ver Lista" : "Ver Mapa"}
        </Button>
      </Affix>

      {anuncios && <SearchContainer todosLosAnuncios={anuncios} />}
      {/* we have to duplicate here because mantine does not let u condition on screen size */}
      <Grid
        // bg="grape"
        maw={"100%"}
        justify="center"
        visibleFrom="sm"
      >
        {anuncios &&
          !showMap &&
          anuncios.map((anuncio, index) => {
            return (
              <Grid.Col maw={"25%"} key={index}>
                <TestCardMantine key={index} anuncio={anuncio} />
              </Grid.Col>
            );
          })}

        {showMap && (
          <Grid.Col maw={"100%"}>
            <AllAnunciosMap selectPosition={null} todosAnuncios={anuncios} />
          </Grid.Col>
        )}
      </Grid>

      {anuncios &&
        !showMap &&
        anuncios.map((anuncio, index) => {
          return (
            <Group key={index} hiddenFrom="sm">
              <TestCardMantine key={index} anuncio={anuncio} />
            </Group>
          );
        })}

      <Stack>
        {showMap && (
          <Group maw={"100%"} hiddenFrom="sm">
            <AllAnunciosMap selectPosition={null} todosAnuncios={anuncios} />
          </Group>
        )}
        {/* end of duplication */}

        {!showMap && (
          <Group mt={5} pb={40}>
            <MyPagination />
          </Group>
        )}
      </Stack>
    </Stack>
    // </Center>
  );
}
