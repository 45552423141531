import { Center, Fieldset, Group } from "@mantine/core";
import { TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { PasswordInput, Stack, Button } from "@mantine/core";
import { useState } from "react";
import { IconX, IconCheck } from "@tabler/icons-react";
import { Progress, Text, Popover, Box, rem, Paper } from "@mantine/core";
import { useEffect } from "react";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useSignup } from "../hooks/useSignup";
import serverUrl from "../GetServerUrl";
import axios from "axios";
import { useParams } from "react-router-dom";

function PasswordRequirement({ meets, label }) {
  return (
    <Text
      c={meets ? "teal" : "red"}
      style={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? (
        <IconCheck style={{ width: rem(14), height: rem(14) }} />
      ) : (
        <IconX style={{ width: rem(14), height: rem(14) }} />
      )}{" "}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

const requirements = [
  { re: /[0-9]/, label: "Incluye Numero" },
  { re: /[a-z]/, label: "Incluye letra minuscula" },
  { re: /[A-Z]/, label: "Incluye letra mayuscula" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Incluye  simbolo" },
];

function getStrength(newpassword) {
  let multiplier = newpassword.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(newpassword)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

export function MyResetPassword() {
  let { id, token } = useParams();

  const [popoverOpened, setPopoverOpened] = useState(false);
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [errorText, setErrorText] = useState("");

  const [value, setValue] = useState("");
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(value)}
    />
  ));

  const strength = getStrength(value);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  const [visible, { toggle }] = useDisclosure(false);
  const form = useForm({
    initialValues: {
      newpassword: "",
      repeatPassword: "",
    },
    validate: {
      newpassword: (value) =>
        value.length < 2 ? "newpassword must have at least 2 letters" : null,

      newpassword: (value) => {
        for (const requirement of requirements) {
          if (!requirement.re.test(value)) {
            return "No " + requirement.label;
          }
        }

        return null;
      },

      repeatPassword: (value, values) => {
        console.log("llll", values);
        return value !== values.newpassword ? "Passwords did not match" : null;
      },
    },
  });

  const handleSubmit = async (values) => {
    console.log("###", values);

    if (!id || !token) {
      return;
    }

    setSubmitted(true);
    axios
      .post(
        `${serverUrl}/reset-password`,
        { id: id, token: token, newPassword: values.newPassword },
        {}
      )
      .then((response) => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.log("error", e);
        setError(true);
        setErrorText(e.response.data.error);
      });
  };

  return (
    <Center>
      <Paper p={40} radius={10} withBorder>
        <Stack>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Popover
              opened={popoverOpened}
              position="bottom"
              width="target"
              transitionProps={{ transition: "pop" }}
            >
              <Popover.Target>
                <div
                  onFocusCapture={() => setPopoverOpened(true)}
                  onBlurCapture={() => setPopoverOpened(false)}
                >
                  <PasswordInput
                    mt={10}
                    withAsterisk
                    label="Nueva contraseña"
                    placeholder="Contraseña"
                    visible={visible}
                    onVisibilityChange={toggle}
                    {...form.getInputProps("newpassword")}
                    onChange={(event) => {
                      // we have to do this because mantine form has it's own onChange
                      // and the password strength meter has on change too
                      // so we are trying to combine them like this
                      let originalOnChange =
                        form.getInputProps("newpassword").onChange;
                      originalOnChange(event);
                      // console.log("%%%%%", form.getInputProps("password"));
                      setValue(event.currentTarget.value);
                    }}
                  />
                </div>
              </Popover.Target>
              <Popover.Dropdown>
                <Progress color={color} value={strength} size={5} mb="xs" />
                <PasswordRequirement
                  label="Incluye por lo menos 8 caracteres"
                  meets={value.length > 7}
                />
                {checks}
              </Popover.Dropdown>
            </Popover>
            <PasswordInput
              mt={10}
              {...form.getInputProps("repeatPassword")}
              withAsterisk
              label="Confirmar Contraseña"
              visible={visible}
              onVisibilityChange={toggle}
            />
            <Group mt={20} grow>
              <Button disabled={submitted} type="submit" color="#ff8a65">
                <Text fw={700} c={"white"}>
                  Enviar
                </Text>
              </Button>
            </Group>
          </form>

          <Group mt={20}>{error && <Text fw={700}>{errorText}</Text>}</Group>

          <Group justify="flex-end" mt={"md"}>
            {submitted && (
              <Group>
                <Button
                  color="#ff8a65"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  {" "}
                  Back to Login
                </Button>

                <Text fw={700}>La contraseña ha sido cambiado con exito!!</Text>
              </Group>
            )}
          </Group>
        </Stack>
      </Paper>
    </Center>
  );
}
