import { Group, Loader, TextInput } from "@mantine/core";
import { Stack, Button } from "@mantine/core";
import { useState } from "react";
import imageCompression from "browser-image-compression";
import { useAuthContext } from "../hooks/useAuthContext";
import { FileButton } from "@mantine/core";
import { MdDelete } from "react-icons/md";
import {
  Textarea,
  Text,
  Checkbox,
  Select,
  Paper,
  NumberInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";

import MyMapBox from "./MyMapBox";
import axios from "axios";
import serverUrl from "../GetServerUrl";

import { OurContext } from "../context/OurContext";
import { useContext } from "react";

const propiedad_options = [
  "Casa",
  "Apartamento",
  "Villa",
  "Solar",
  "Terreno",
  "Penthouse",
  "Finca",
  "Lofts",
  "Estudios",
  "Oficina",
  "Hotel",
  "Townhouse",
  "Otro",
  "Naves",
  "Locales",
];

export function MyAddAnuncioForm() {
  const [signUpSuccessfull, setSignUpSuccessfull] = useState(false);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const { getAllAnuncios, getMisAnuncios } = useContext(OurContext);

  const navigate = useNavigate();

  const [address, setAddress] = useState("");
  const [place, setPlace] = useState("");
  const [region, setRegion] = useState("");
  const [locality, setLocality] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  // const [value, setValue] = useState("");
  const [value, setValue] = useState(["Rampas"]);

  const [images, setImages] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [fileList, setFileList] = useState([]);
  const { user } = useAuthContext();

  // const [visible, { toggle }] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      name: user.name,
      apellido: user.apellido,
      empresa: "",
      movil: "",
      title: "",
      email: user.email,
      moneda: "RD",
      price: "",
      metros: "",
      habitaciones: "",
      banos: "",
      nivel: "",
      balcon: false,
      patio: false,
      piscina: false,
      parqueo: false,
      proposito: "venta",
      propiedad: "Casa",
      description: "",
    },
    validate: {
      name: (value) => (value ? null : "Nombre es requerido"),
      apellido: (value) => (value ? null : "Apellido es requerido"),
      price: (value) => (value ? null : "Precio es requerido"),
      // moneda: (value) => (value ? null : "Moneda es requerido"),
      // address: (value) => (value ? null : "Dirección es requerido"),
      // proposito: (value) => (value ? null : "Proposito es requerido"),
      // propiedad: (value) => (value ? null : "Propiedad es requerido"),
      description: (value) => (value ? null : "Descripció es requerido"),
      movil: (value) => (value ? null : "Celular es requerido"),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleSubmit = async (formValues) => {
    console.log("###0", formValues);

    if (!address) {
      return;
    }

    console.log("###1", address);

    if (isLoading) {
      return;
    }
    setIsLoading(true);

    setError(null);
    if (!user) {
      setError("You must be logged in");
      return;
    }

    const fd = new FormData();

    for (const file of fileList) {
      fd.append("images", file);
    }
    // TODO check how endi images work
    // change this part accordingly

    // fd.append("photo", imageFile, imageFile.name);
    // [...images].forEach((image) => {
    //   fd.append("photo", image);
    // });

    // TODO add balcon patio etc flags

    for (let key in formValues) {
      fd.append(key, formValues[key]);
    }

    fd.append("place", place);
    fd.append("region", region);
    fd.append("locality", locality);
    fd.append("address", address);

    fd.append("latitude", latitude);
    fd.append("longitude", longitude);

    let config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .post(`${serverUrl}/anuncio`, fd, config)
      .then((response) => {
        getAllAnuncios();
        getMisAnuncios();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });

    if (!error) {
      navigate("/misAnuncios");
    }
  };

  async function resizeImage(imageFile) {
    const options = {
      maxSizeMB: 0.1,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  async function onImagesChange(files) {
    const resizedImages = await Promise.all(
      Object.keys(files).map(async (key) => {
        const file = files[key];
        const compressed = await resizeImage(file);
        var compressed_file = blobToFile(compressed, file.name);
        return compressed_file;
      })
    );

    setImages([...images, ...resizedImages].slice(0, 30));
  }

  //TODO use agregarAnuncio function from the previous code

  return (
    // <Center>
    <>
      {/* below for desktop */}
      <Group grow visibleFrom="sm">
        <Paper p={20} radius={10} withBorder>
          <Stack>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <Stack>
                <Group grow>
                  <Select
                    label="Proposito"
                    // placeholder="venta"
                    data={["venta", "alquiler", "construir"]}
                    {...form.getInputProps("proposito")}
                  />
                  <Select
                    label="Propiedad"
                    // placeholder="Casa"
                    data={propiedad_options}
                    {...form.getInputProps("propiedad")}
                  />
                </Group>

                <TextInput
                  label="Titulo de anuncio"
                  placeholder="Titulo"
                  {...form.getInputProps("title")}
                />
                <Group grow>
                  <Select
                    label="Moneda"
                    // placeholder="RD"
                    data={["RD", "US"]}
                    {...form.getInputProps("moneda")}
                  />

                  <NumberInput
                    hideControls
                    label="Precio"
                    placeholder="Precio"
                    {...form.getInputProps("price")}
                  />
                </Group>

                <Group grow>
                  <NumberInput
                    hideControls
                    label="Metros"
                    placeholder="Metros"
                    {...form.getInputProps("metros")}
                  />

                  <NumberInput
                    hideControls
                    label="Habitaciones"
                    placeholder="Habitaciones"
                    {...form.getInputProps("habitaciones")}
                  />

                  <NumberInput
                    hideControls
                    label="Baños"
                    placeholder="Baños"
                    {...form.getInputProps("banos")}
                  />
                  <NumberInput
                    hideControls
                    label="Nivel"
                    placeholder="Nivel"
                    {...form.getInputProps("nivel")}
                  />
                </Group>

                <Group grow>
                  <Checkbox
                    label="Balcon"
                    color="#ff8a65"
                    {...form.getInputProps("balcon")}
                  />
                  <Checkbox
                    label="Patio"
                    color="#ff8a65"
                    {...form.getInputProps("patio")}
                  />
                  <Checkbox
                    label="Piscina"
                    color="#ff8a65"
                    {...form.getInputProps("piscina")}
                  />
                  <Checkbox
                    label="Parqueo"
                    color="#ff8a65"
                    {...form.getInputProps("parqueo")}
                  />
                </Group>

                <Group grow>
                  <Textarea
                    // size="xl"
                    label="Descripción"
                    placeholder="Apartamento familiar"
                    {...form.getInputProps("description")}
                  />
                </Group>

                {/* <Text>Contactos:</Text> */}

                <Group grow>
                  <TextInput
                    label="Nombre"
                    placeholder="Nombre"
                    {...form.getInputProps("name")}
                  />
                  <TextInput
                    label="Apellido"
                    placeholder="Apellido"
                    {...form.getInputProps("apellido")}
                  />
                </Group>

                <Group grow>
                  <NumberInput
                    hideControls
                    label="Movil"
                    placeholder="Movil"
                    {...form.getInputProps("movil")}
                  />
                  <TextInput
                    transform="lowercase"
                    label="Email"
                    placeholder="Email"
                    {...form.getInputProps("email")}
                  />
                </Group>

                <Group grow>
                  <TextInput
                    label="Empresa"
                    placeholder="Empresa"
                    {...form.getInputProps("empresa")}
                  />
                </Group>

                <Group>
                  <>
                    <Group justify="center">
                      <FileButton
                        onChange={(values) => {
                          console.log("values", values);

                          if (values.length === 0 && fileList.length !== 0) {
                            return;
                          }

                          let fileNames = fileList.map((unFile) => unFile.name);
                          let filesToAdd = values.filter(
                            (newFile) => !fileNames.includes(newFile.name)
                          );

                          let newFileList = [...fileList, ...filesToAdd];

                          setFileList(newFileList);

                          onImagesChange(newFileList);
                        }}
                        accept="image/png,image/jpeg"
                        multiple
                      >
                        {(props) => (
                          <Button {...props} color="#5DA6A6">
                            Upload image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </>
                </Group>

                <Group
                // justify="space-between"
                >
                  {fileList.map((unFile, index) => {
                    return (
                      <Stack key={index}>
                        <img
                          key={index}
                          src={URL.createObjectURL(unFile)}
                          width={100}
                          height={100}
                        />
                        <Button
                          style={{
                            borderRadius: 5,
                          }}
                          color="salmon"
                          onClick={() => {
                            let copiaImages = [...fileList];
                            copiaImages.splice(index, 1);
                            setFileList(copiaImages);
                          }}
                        >
                          <MdDelete
                            // color="black"
                            size={20}
                          />
                        </Button>
                      </Stack>
                    );
                  })}
                </Group>

                <Group justify="flex-end" mt={"md"}>
                  <Button type="submit" color="#ff8a65" disabled={isLoading}>
                    {isLoading && <Loader size={20} />}
                    {!isLoading && (
                      <Text fw={700} c={"white"}>
                        Enviar
                      </Text>
                    )}
                  </Button>

                  {error && (
                    <Text fw={700} c={"red"}>
                      {error}
                    </Text>
                  )}
                </Group>
              </Stack>
            </form>
          </Stack>
        </Paper>

        <Stack
        // bg="yellow"
        >
          <MyMapBox
            address={address}
            setAddress={setAddress}
            setRegion={setRegion}
            setPlace={setPlace}
            setLocality={setLocality}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            errors={errors}
          />
        </Stack>
      </Group>

      {/* below for mobile */}
      <Stack grow hiddenFrom="sm">
        <Stack
        // bg="yellow"
        >
          <MyMapBox
            address={address}
            setAddress={setAddress}
            setRegion={setRegion}
            setPlace={setPlace}
            setLocality={setLocality}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            errors={errors}
          />
        </Stack>

        <Paper
          p={20}
          radius={10}
          withBorder
          //  bg={"green"}
        >
          <Stack>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <Stack>
                <Select
                  label="Proposito"
                  // placeholder="venta"
                  data={["venta", "alquiler", "construir"]}
                  {...form.getInputProps("proposito")}
                />

                <Select
                  label="Propiedad"
                  // placeholder="Casa"
                  data={propiedad_options}
                  {...form.getInputProps("propiedad")}
                />
                <TextInput
                  label="Titulo de anuncio"
                  placeholder="Titulo"
                  {...form.getInputProps("title")}
                />
                <Select
                  label="Moneda"
                  // placeholder="RD"
                  data={["RD", "US"]}
                  {...form.getInputProps("moneda")}
                />

                <NumberInput
                  hideControls
                  label="Precio"
                  placeholder="Precio"
                  {...form.getInputProps("price")}
                />

                <NumberInput
                  hideControls
                  label="Metros"
                  placeholder="Metros"
                  {...form.getInputProps("metros")}
                />

                <NumberInput
                  hideControls
                  label="Habitaciones"
                  placeholder="Habitaciones"
                  {...form.getInputProps("habitaciones")}
                />

                <NumberInput
                  hideControls
                  label="Baños"
                  placeholder="Baños"
                  {...form.getInputProps("banos")}
                />
                <NumberInput
                  hideControls
                  label="Nivel"
                  placeholder="Nivel"
                  {...form.getInputProps("nivel")}
                />

                <Checkbox
                  label="Balcon"
                  color="#ff8a65"
                  {...form.getInputProps("balcon")}
                />
                <Checkbox
                  label="Patio"
                  color="#ff8a65"
                  {...form.getInputProps("patio")}
                />
                <Checkbox
                  label="Piscina"
                  color="#ff8a65"
                  {...form.getInputProps("piscina")}
                />
                <Checkbox
                  label="Parqueo"
                  color="#ff8a65"
                  {...form.getInputProps("parqueo")}
                />

                <Textarea
                  // size="xl"
                  label="Descripción"
                  placeholder="Apartamento familiar"
                  {...form.getInputProps("description")}
                />

                {/* <Text>Contactos:</Text> */}

                <TextInput
                  label="Nombre"
                  placeholder="Nombre"
                  {...form.getInputProps("name")}
                />
                <TextInput
                  label="Apellido"
                  placeholder="Apellido"
                  {...form.getInputProps("apellido")}
                />

                <NumberInput
                  hideControls
                  label="Movil"
                  placeholder="Movil"
                  {...form.getInputProps("movil")}
                />
                <TextInput
                  transform="lowercase"
                  label="Email"
                  placeholder="Email"
                  {...form.getInputProps("email")}
                />

                <TextInput
                  label="Empresa"
                  placeholder="Empresa"
                  {...form.getInputProps("empresa")}
                />

                <Group>
                  <>
                    <Group justify="center">
                      <FileButton
                        onChange={(values) => {
                          console.log("values", values);

                          if (values.length === 0 && fileList.length !== 0) {
                            return;
                          }

                          let fileNames = fileList.map((unFile) => unFile.name);
                          let filesToAdd = values.filter(
                            (newFile) => !fileNames.includes(newFile.name)
                          );

                          let newFileList = [...fileList, ...filesToAdd];

                          setFileList(newFileList);

                          onImagesChange(newFileList);
                        }}
                        accept="image/png,image/jpeg"
                        multiple
                      >
                        {(props) => (
                          <Button {...props} color="#5DA6A6">
                            Upload image
                          </Button>
                        )}
                      </FileButton>
                    </Group>
                  </>
                </Group>

                <Group justify="center">
                  {fileList.map((unFile, index) => {
                    return (
                      <Stack key={index}>
                        <img
                          key={index}
                          src={URL.createObjectURL(unFile)}
                          width={80}
                          height={80}
                        />
                        <Button
                          style={{
                            borderRadius: 5,
                          }}
                          color="salmon"
                          onClick={() => {
                            let copiaImages = [...fileList];
                            copiaImages.splice(index, 1);
                            setFileList(copiaImages);
                          }}
                        >
                          <MdDelete
                            // color="black"
                            size={20}
                          />
                        </Button>
                      </Stack>
                    );
                  })}
                </Group>

                <Group grow mt={"md"}>
                  <Button type="submit" color="#ff8a65" disabled={isLoading}>
                    {isLoading && <Loader size={20} />}
                    {!isLoading && (
                      <Text fw={700} c={"white"}>
                        Enviar
                      </Text>
                    )}
                  </Button>

                  {error && (
                    <Text fw={700} c={"red"}>
                      {error}
                    </Text>
                  )}
                </Group>
              </Stack>
            </form>
          </Stack>
        </Paper>
      </Stack>
    </>
    // </Center>
  );
}
