const mappingEmpresa = {
  hazim: "HAZIM RODRIGUEZ",
  tucasard: "TU CASA RD",
  noves: "NOVES PROPERTIES",
  habitame: "HABITAME",
  cindy: "Cindy REAL ESTATE",
  inversionisto: "Inversionisto",
};

export default mappingEmpresa;
