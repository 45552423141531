import * as React from "react";
// import Pagination from "@mui/material/Pagination";
import { Pagination, Stack } from "@mantine/core";

// import Stack from "@mui/material/Stack";

import { OurContext } from "../context/OurContext";
import { useContext } from "react";

export default function MyPagination(props) {
  const { cantidadDePaginas, setCurrentPage, currentPage } =
    useContext(OurContext);

  return (
    <>
      <Pagination
        color={"#ff8a65"}
        total={cantidadDePaginas}
        onChange={setCurrentPage}
        value={currentPage}
        visibleFrom="sm"
        // size={"sm"}
      />
      <Pagination
        hiddenFrom="sm"
        color={"#ff8a65"}
        total={cantidadDePaginas}
        onChange={setCurrentPage}
        value={currentPage}
        size={"sm"}
      />
    </>
  );
}
