import { Center, Fieldset, Group } from "@mantine/core";
import { TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { PasswordInput, Stack, Button } from "@mantine/core";
import { useState } from "react";
import { IconX, IconCheck } from "@tabler/icons-react";
import { Progress, Text, Popover, Box, rem, Paper } from "@mantine/core";
import { useEffect } from "react";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";

import serverUrl from "../GetServerUrl";
import axios from "axios";
import { useParams } from "react-router-dom";

export function MyEmailConfirmed() {
  let { id, token } = useParams();
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [errorText, setErrorText] = useState("");
  const handleSubmit = async (values) => {
    if (!id || !token) {
      return;
    }
    axios
      .post(`${serverUrl}/confirmEmail`, { id: id, token: token }, {})
      .then((response) => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.log("error", e);
        setError(true);
        setErrorText(e.response.data.error);
      });
  };

  useEffect(() => {
    // console.log("### useeffect", id, token);
    if (!id || !token) {
      return;
    }

    axios
      .post(`${serverUrl}/confirmEmail`, { id: id, token: token }, {})
      .then((response) => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.log("error", e);
        setError(true);
        setErrorText(e.response.data.error);
      });
  }, []);

  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  return (
    <Center>
      <Paper p={80} radius={10} withBorder>
        <Stack>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            {submitted && (
              <Text>Your Email has been confirmed. Please login again.</Text>
            )}

            {!submitted && (
              <Text>Please click this to confirm your email address.</Text>
            )}

            <Group justify="center " mt={"md"}>
              {submitted ? (
                <Group>
                  <Button
                    color="#ff8a65"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    {" "}
                    Back to Login
                  </Button>
                </Group>
              ) : (
                <Button
                  type="submit"
                  color="#ff8a65"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Confirm Email
                </Button>
              )}
            </Group>
          </form>
        </Stack>
      </Paper>
    </Center>
  );
}
