import { Image, Card, Text, Group, Button, Anchor, Space } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { MdDelete } from "react-icons/md";
import "@mantine/carousel/styles.css";
import { OurContext } from "../context/OurContext";
import { useContext } from "react";
import mappingEmpresa from "./Empresas";
import { useState } from "react";
import classes from "./mantine_css/CarouselCard.module.css";
// import { nprogress, NavigationProgress } from "@mantine/nprogress";
import { NumberFormatter } from "@mantine/core";
import { Stack } from "@mui/material";

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
}

export function TestCardMantine(props) {
  const { showDetailedView } = useContext(OurContext);
  const [showDatos, setShowDatos] = useState(false);

  const item = props.anuncio;

  let donde = "";
  let empresa = "";
  if (item.source) {
    // console.log(mappingEmpresa[item.source]);
    empresa = "Empresa: " + mappingEmpresa[item.source];
  }

  if (item.locality) {
    donde = donde + item.locality;
  }

  if (item.locality && item.place) {
    donde = donde + ", " + item.place;
  }

  if (!item.locality && item.place) {
    donde = donde + item.place;
  }

  if ((item.place || item.locality) && item.region) {
    donde = donde + ", " + item.region;
  }

  if (!(item.place || item.locality) && item.region) {
    donde = donde + item.region;
  }

  // en {`${item.locality}, ${item.place}, ${item.region}`}

  if (donde === "") {
    donde = "República Dominicana";
  }

  const slides = item.image_urls.map((image_url, index) => (
    <Carousel.Slide key={index}>
      <Image src={image_url} height={220} />

      {/* <Image src={"https://tucasataina.b-cdn.net/images/001e1f05-ced2-4761-83b4-cc3c67ea3aa0.webp"} height={220} /> */}
    </Carousel.Slide>
  ));

  return (
    // <Center maw={400} bg="red"  >
    <Card
      radius="md"
      withBorder
      // padding="xl"
      // h={600}
      // bg="green"
    >
      <Card.Section>
        <Carousel
          withIndicators
          loop
          classNames={{
            root: classes.carousel,
            controls: classes.carouselControls,
            indicator: classes.carouselIndicator,
          }}
        >
          {slides}
          {slides.length === 0 && (
            <Carousel.Slide>
              <Image
                fallbackSrc="https://placehold.co/600x400?text=TuCasaTaina"
                height={220}
              />
            </Carousel.Slide>
          )}
        </Carousel>
      </Card.Section>

      <Stack justify="space-between" mt="lg">
        <Text
          component="h1"
          fw={500}
          fz="lg"
          lineClamp={1}
          mt={20}
          // bg="greezn"
        >
          {item.title}
        </Text>

        <Group gap={6}>
          <Group>
            <Text
              fz="xs"
              fw={500}
              // bg="blue"
            >
              {item.proposito && item.proposito.toUpperCase()}
            </Text>
          </Group>

          <Text fz="xs" fw={500}>
            {item.propiedad && item.propiedad.toUpperCase()}
          </Text>

          {item.habitaciones && (
            <Text fz="xs" fw={500}>
              {item.habitaciones} hab.
            </Text>
          )}

          {item.metros && (
            <Text fz="xs" fw={500}>
              {item.metros + "m²"}
            </Text>
          )}
        </Group>
      </Stack>

      <Group pt={3}>
        <Text>{empresa}</Text>
      </Group>
      <Group
      // gap={3}
      >
        <Text
          fz="sm"
          mt="sm"
          // truncate="end"
          lineClamp={1}
          // bg="red"
        >
          {/* <Center> */}
          📍
          {donde}
          {/* </Center> */}
        </Text>
      </Group>
      <Text fz="sm" c="dimmed" mt="sm" lineClamp={3}>
        {item.description}
      </Text>

      <Group mt="md" justify="space-between">
        {item.price && (
          <Text fz="md" fw={600} style={{ lineHeight: 1 }}>
            <NumberFormatter
              prefix={item.moneda + "$ "}
              value={item.price}
              thousandSeparator
            />
          </Text>
        )}
        {!item.price && <Space h="md" />}
      </Group>

      <Group mt="md" justify="space-between">
        <Button
          color="#5DA6A6"
          // onClick={() => nprogress.start(showDetailedView(item))}
          onClick={() => {
            showDetailedView(item);
          }}
          radius="md"
        >
          Información
        </Button>

        {!showDatos && (
          <Button
            color="#5DA6A6"
            onClick={() => {
              setShowDatos(true);
            }}
            radius="md"
          >
            Contacto
          </Button>
        )}
        {showDatos && (
          <Anchor variant="subtle" href={`tel:${item.movil}`} radius="md">
            {formatPhoneNumber(item.movil)}
          </Anchor>
        )}

        {props.queHacerOnDelete2 && (
          <Button
            color="salmon"
            // variant="transparent"
            // leftSection={<MdDelete size={20} color="black" />}
            onClick={() => {
              props.queHacerOnDelete2(item._id);
            }}
          >
            <MdDelete size={20} />
          </Button>
        )}
      </Group>

      {/* <Card.Section>Last section</Card.Section> */}
    </Card>
    // </Center>
  );
}
