import * as React from "react";

import {
  Group,
  Button,
  Text,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
  useMantineTheme,
  ActionIcon,
  AppShell,
  Flex,
  Stack,
  Anchor,
  Center,
} from "@mantine/core";
// import { MantineLogo } from "@mantinex/mantine-logo";
import { useDisclosure } from "@mantine/hooks";

import { useHref, useNavigate } from "react-router-dom";

export function MyFooter() {
  const navigate = useNavigate();

  /////////////////
  const [opened, { toggle }] = useDisclosure();
  return (
    <AppShell.Footer>
      <Flex
        mih={30}
        gap="xs"
        justify="center"
        align="center"
        direction="column"
        wrap="wrap"
      >
        <Group justify="center">
          <Anchor href={`mailto: info@tucasataina.com`} target="_blank">
            <Text color="#5DA6A6">info@tucasataina.com</Text>
          </Anchor>
          <Text color="#A0A0A0">
            <h5> Copyright © TuCasaTaina 2024</h5>
          </Text>
        </Group>
      </Flex>
    </AppShell.Footer>
  );
}
