import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import serverUrl from "../GetServerUrl";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const signup = async (email, password, name, apellido) => {
    setIsLoading(true);
    setError(null);

    const response = await fetch(`${serverUrl}/api/user/signup`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password, name, apellido }),
    });

    const json = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setError(json.error);
      return false;
    }
    if (response.ok) {
      // // save the user to local storage
      // localStorage.setItem("user", JSON.stringify(json));

      // // update the auth context
      // dispatch({ type: "LOGIN", payload: json });

      // // update loading state
      // setIsLoading(false);

      // we want to control that the user confirms their email
      // before allowing them to do anything else
      return true;
    }
  };

  return { signup, isLoading, error };
};
