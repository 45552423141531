import { Center, Fieldset, Group } from "@mantine/core";
import { TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { PasswordInput, Stack, Button } from "@mantine/core";
import { useState } from "react";
import { IconX, IconCheck } from "@tabler/icons-react";
import { Progress, Text, Popover, Box, rem, Paper } from "@mantine/core";
import { useEffect } from "react";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../hooks/useLogin";

export function Mylogin() {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const { login, error, isLoading } = useLogin();
  const navigate = useNavigate();

  const [value, setValue] = useState("");

  const [visible, { toggle }] = useDisclosure(false);
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleSubmit = async (values) => {
    // console.log("###", checks)

    const result = await login(values.email, values.password);

    if (result) {
      console.log("result", error, result);
    } else {
      console.log("result is false", error);
    }
  };

  return (
    <Center>
      <Paper p={40} radius={10} withBorder>
        <Stack>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              transform="lowercase"
              mt={10}
              label="Email"
              placeholder="Email"
              {...form.getInputProps("email")}
            />

            <Popover
              opened={popoverOpened}
              position="bottom"
              width="target"
              transitionProps={{ transition: "pop" }}
            >
              <Popover.Target>
                <div
                  onFocusCapture={() => setPopoverOpened(true)}
                  onBlurCapture={() => setPopoverOpened(false)}
                >
                  <PasswordInput
                    mt={10}
                    {...form.getInputProps("password")}
                    error={error}
                    label="Contraseña"
                    placeholder="Contraseña"
                    visible={visible}
                    onVisibilityChange={toggle}
                  />
                </div>
              </Popover.Target>
            </Popover>

            <Group justify="flex-end" mt={"md"}>
              <Button type="submit" color="#ff8a65">
                <Text fw={700} c={"white"}>
                  Inicia Sesión
                </Text>
              </Button>
            </Group>

            <Group mt={20} justify="space-between">
              <Text fw={700}>No tienes una cuenta?</Text>
              <Button
                variant="outline"
                type="submit"
                color="#ff8a65"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                <Text fw={700} c={"#ff8a65"}>
                  Registrate
                </Text>
              </Button>
            </Group>
            <Group mt={20} justify="space-between">
              <Text fw={700}>Olvidaste la contraseña?</Text>
              <Button
                variant="outline"
                type="submit"
                color="#ff8a65"
                onClick={() => {
                  navigate("/forgotPassword");
                }}
              >
                <Text fw={700} c={"#ff8a65"}>
                  Cambiar Contraseña
                </Text>
              </Button>
            </Group>
          </form>
        </Stack>

        {error && error === "Please confirm your email address first" && (
          <Stack>
            <Text fw={700} c={"green"}>
              No tienes el link para confirmar tu Email?
            </Text>

            <Button
              type="submit"
              color="white"
              onClick={() => {
                navigate("/requestEmailConfirmation");
              }}
            >
              <Text fw={700} c={"#ff8a65"}>
                Confirma Email
              </Text>
            </Button>
          </Stack>
        )}
      </Paper>
    </Center>
  );
}
