import React, { useEffect } from "react";

import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// import AnuncioCardMap from "./AnuncioCardMap";
import { Group, Stack, Center } from "@mantine/core";
import { MyAdsMap } from "./MyAdsMap";
import { useElementSize } from "@mantine/hooks";
import { rem } from "@mantine/core";

const icon = L.icon({
  iconUrl: "./placeholder.png",
  iconSize: [38, 38],
});

const position = [51.505, -0.09];

function ResetCenterView(props) {
  const { todosAnuncios } = props;
  const selectPosition = todosAnuncios[0];
  const map = useMap();

  useEffect(() => {
    if (!selectPosition) return;
    map.setView(
      L.latLng(selectPosition.latitude, selectPosition.longitude),
      map.getZoom(),
      {
        animate: true,
      }
    );
  }, [selectPosition]);

  return null;
}

export default function AllAnunciosMap(props) {
  const { todosAnuncios } = props;
  const { ref, width, height } = useElementSize();

  return (
    // <Group
    //   justify="center"
    //   style={{
    //     // display: "center",
    //     // flexDirection: "row",
    //     width: "1500px",
    //     height: "500px",
    //   }}
    // >
    //   <div
    //     style={{
    //       width: "100%",
    //       height: "100%",
    //       // zIndex: 0,
    //     }}
    //   >

    <Group ref={ref} style={{ width: "100%", height: rem(500) }}>
      <MapContainer
        center={position}
        zoom={13}
        style={{ width: "100%", height: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=T9ibxxrWCveXnTLrzu4G"
        />
        {todosAnuncios.map((unAnuncio, index) => {
          return (
            <Marker
              position={[unAnuncio.latitude, unAnuncio.longitude]}
              icon={icon}
              key={index}
              eventHandlers={{
                click: (event) => event.target.openPopup(),
                // closePopup: (event) => event.target.closePopup(),
              }}
            >
              <Popup minWidth={300}>
                <MyAdsMap anuncio={unAnuncio} />
                {/* <AnuncioCardMap anuncioDatos={unAnuncio} /> */}
              </Popup>
            </Marker>
          );
        })}
        <ResetCenterView todosAnuncios={todosAnuncios} />
      </MapContainer>
    </Group>

    //   {/* </div>
    // </Group> */}
  );
}
