import * as React from "react";
import { Helmet } from "react-helmet-async";

const HelmetTags = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Descubre el portal líder que reúne todas las empresas inmobiliarias en República Dominicana. Encuentra las mejores propiedades en un solo lugar, explorando una amplia variedad de opciones para tu hogar o inversión. Simplificamos tu búsqueda, ofreciéndote acceso fácil y rápido a la información de propiedades de confianza. ¡Explora ahora y encuentra el lugar perfecto para ti!"
        />
        <meta
          name="description"
          content="Explora una amplia variedad de propiedades en venta en República Dominicana a través de las principales empresas inmobiliarias. Encuentra tu hogar ideal o la inversión perfecta en nuestro portal unificado. Descubre las mejores opciones inmobiliarias de confianza en un solo lugar."
        />
        <meta
          name="description"
          content="vender casa, vender apartamento, alquilar casa, alquilar apartamento"
        />
        <meta
          name="description"
          content="comprar casa, comprar apartamento, alquila casa, alquila apartamento"
        />

        <meta
          name="description"
          content="busca casa en republica dominicana, busca apartamento en republica dominicana"
        />
        <meta
          name="description"
          content="Anuncia inmuble en republica dominicana"
        />
        <meta
          name="description"
          content="busca inmuble en republica dominicana"
        />

        <link rel="canonical" href="/" />
        <meta property="og:title" content="Tu Casa Taina" />
        <meta
          property="og:description"
          content=" Venta y Alquiler de Casas y Apartamentos en República Dominicana | Inmobiliaria Tucasataina "
        />
        <meta property="og:image" content="/favicon-32x32.png" />
        <meta property="og:url" content="www.tucasataina.com" />
        <meta property="og:type" content="website" />
      </Helmet>
    </>
  );
};

export default HelmetTags;
